<template>
  
    <b-card class="mb-2 mt-2" bg-variant="transparent" no-body>
        
        <b-card-body class="info-horizontal-card">
            <b-card-title class="mb-1 info-horizontal-card__title" style="color: #141c40">
                Хотите зарабатывать больше?
            </b-card-title>
            <b-card-text class="info-horizontal-card__text">
               Посмотрите подборку самых доходных офферов
            </b-card-text>
        </b-card-body>
        
    </b-card>
</template>

<script>
import Tickets from "@/modules/tickets/"

export default {
    data() {
        return {
            Tickets,
        }
    },
    computed: {
        faq_url() {
            return `${process.env.VUE_APP_BASE_URL}knowledge/faq`
        }
    }
}
</script>

<style lang="scss" scoped>
    .info-horizontal-card {
        background: linear-gradient(94.42deg, #E8E6FD 0%, #EFE6FD 102.82%);
        border-radius: 16px;
        padding: 40px 34px 48px 34px;

        &__title {
           font-weight: 600;
            font-size: 28px;
            line-height: 32px;
        }

        &__text {
            font-size: 16px; 
            color: #787E8E; 
            line-height: 24px;
        }
    }

    .dark-layout  {
        .info-horizontal-card {
            background-color: #262642 !important;
        }
    }
</style>