<template>
  <b-card no-body v-ripple style="cursor: pointer" @click="view">
    <b-card-body class="stretch-offer-card" style="padding: 0px">
      <div
        :style="{
          margin: '0px',
          height: '100%',
          '--thumb': `url(${item.medias[0].urls.thumb})`,
          '--placeholder': `url(${item.medias[0].urls.placeholder})`,
          borderRadius: '24px',
        }"
        class="attachment stretch-offer-card-body"
      >
        <div class="d-flex flex-column justify-content-between w-100">
          <div class="d-flex justify-content-between">
            <ul
              class="list-unstyled"
              style="
                margin-bottom: 43px;
                display: flex;
                flex-wrap: wrap;
                gap: 4px;
              "
            >
              <li style="display: inline-block">
                <b-badge
                  variant="blur"
                  style="
                    border-radius: 50px;
                    padding: 4px 8px 6px 8px;
                    font-weight: 400;
                  "
                >
                  {{ item.has_category.name }}
                </b-badge>
              </li>
              <li
                style="display: inline-block"
                v-for="(city, index) in item.cities"
                :key="index"
              >
                <b-badge
                  variant="blur"
                  style="
                    border-radius: 50px;
                    padding: 4px 8px 6px 8px;
                    font-weight: 400;
                  "
                >
                  {{ city.name }}
                </b-badge>
              </li>
              <li v-if="item.cities.length == 0" style="display: inline-block">
                <b-badge
                  variant="blur"
                  style="
                    border-radius: 50px;
                    padding: 4px 8px 6px 8px;
                    font-weight: 400;
                  "
                >
                  Вся РФ
                </b-badge>
              </li>
            </ul>
            <div
              :style="mediaViewStyleLogoCard()"
              class="stretch-offer-card-logo"
            ></div>
          </div>
          <!-- <p class="stretch-offer-card-title" v-html="item.title"></p> -->
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    item: Object,
    compact: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    mediaViewStyleLogoCard() {
      return {
        height: "40px",
        width: "94px",
        backgroundImage: `url(${
          this.item.has_company.medias[0].thumb ||
          this.item.has_company.medias[0].urls.thumb
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        borderRadius: "12px",
      };
    },
    view() {
      this.$router.push({
        name: "user-offer-item",
        params: {
          item: this.item.id,
        },
      });
    },
  },
  components: {},
  watch: {},
  mounted() {},
};
</script>

<style lang="scss">
.stretch-offer-card {
  height: 220px;

  .stretch-offer-card-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    /* or 127% */

    display: flex;
    align-items: flex-end;

    /* Day/Text/Inverted */

    color: #ffffff;
  }

  .stretch-offer-card-body {
    padding: 20px;
  }
}
</style>
